import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Pages/general"

const TermsPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="Termos de Uso"
      description="Esse são os termos de uso do nosso site, aqui nessa página você irá entender tudo do que se trata nosso site."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle align="left" font>
        Termos de Uso
      </S.GeneralTitle>
      <S.GeneralHighlight>
        Importante! Esses termos de serviço regulam o uso deste site e das
        informações contidas no mesmo, diponibilizado por{" "}
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          {" "}
          Você Empreendedor Digital
        </S.GeneralLink>
        . Ao acessar este site, você atesta seu conhecimento e concordância com
        esses termos de uso. Esses termos de uso podem ser alterados a qualquer
        tempo e sem aviso. A utilização deste site após tais alterações entrarem
        em vigor constitui seu conhecimento e aceitação das mudanças. Por favor,
        consulte os termos de uso antes de cada uso para averiguar modificações.
      </S.GeneralHighlight>
      <S.GeneralSubTitle align="left">
        Acesso às indicações da Aprendendo Desenhar
      </S.GeneralSubTitle>
      <S.GeneralText>
        Para acessar alguns recursos da
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          {" "}
          Aprendendo Desenhar
        </S.GeneralLink>
        , pode ser requerido que você forneça alguns detalhes para inscrição ou
        outras informações. É uma condição de uso deste site que toda a
        informação que você forneça seja correta, atualizada e completa. Se a
        Empresa acreditar que a informação fornecida não é correta, atualizada
        ou completa, temos o direito de recusar o seu acesso a esse site ou
        qualquer um de seus serviços e também de cancelar, ou suspender seu
        acesso a qualquer tempo sem notificação prévia.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Restrições de uso</S.GeneralSubTitle>
      <S.GeneralText>
        Você pode utilizar esse site para propósitos expressamente permitidos
        por esse site. Você não pode utilizá-lo em qualquer outro objetivo,
        incluindo propósitos comerciais, sem o consentimento prévio expresso e
        escrito da nossa Empresa. Por exemplo, você não pode e tampouco pode
        autorizar a terceiros a (I) associar a marca da nossa Empresa a nenhuma
        outra, e (II) fazer um frame deste site em outro. Para efeitos destes
        Termos de Uso, “associar a marca da nossa Empresa” significa expor nome,
        logo, marca comercial ou outros meios de atribuição, ou identificação em
        fonte de maneira que dê ao usuário a impressão de que tal fonte tem o
        direito de expor, publicar ou distribuir este site, ou o conteúdo
        disponibilizado por ele. Você concorda em cooperar com a Empresa para
        cessar qualquer associação, frame ou hiperlink não autorizados.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">
        Propriedade da Informação
      </S.GeneralSubTitle>
      <S.GeneralText>
        O material e conteúdo (referido nesse documento como “conteúdo”)
        acessíveis neste site e qualquer outro website de propriedade,
        licenciado ou controlado pela nossa Empresa, pertence à Empresa ou da
        fonte que forneceu o conteúdo à nossa Empresa, e nossa Empresa ou a
        fonte em questão detém todo o direito, título e proveito no Conteúdo. Da
        mesma forma, o conteúdo não pode ser copiado, distribuído, republicado,
        carregado, postado ou transmitido por qualquer meio sem o consentimento
        prévio escrito de nossa Empresa, ou ao menos que esteja autorizado de
        forma escrita no nosso site, exceto quando você imprime uma cópia para
        seu uso pessoal somente. Ao fazer isso, você não pode remover, alterar
        ou causar remoção, ou alteração em qualquer copyright, marca comercial,
        nome comercial, marca de serviço ou qualquer outro aviso de propriedade
        no Conteúdo. Modificação ou uso do Conteúdo em qualquer outra forma que
        não as expressamente descritas nesse Termo de Uso viola os direitos de
        propriedade intelectual de nossa Empresa. Nem autoria, nem a propriedade
        intelectual são transferidas para você ao acessar esse site.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Hiperlinks</S.GeneralSubTitle>
      <S.GeneralText>
        Este site pode conter links para outros websites que não são mantidos ou
        mesmo relacionados à nossa Empresa. Hiperlinks para tais sites são
        providos como um serviço para usuários e não são afiliados a este site
        ou à nossa Empresa. Nossa Empresa não revisa todos ou mesmo nenhum
        desses sites e não é responsável pelo conteúdo deles. O usuário assume o
        risco ao acessar esses hiperlinks e nossa Empresa não faz nenhuma
        representação ou dá garantias sobre a plenitude, ou precisão dos
        hiperlinks e os sites ao quais eles direcionam. Finalmente, a conclusão
        de qualquer hiperlink para um site de terceiro não necessariamente
        implica endosso da nossa Empresa a esse site.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Envios</S.GeneralSubTitle>
      <S.GeneralText>
        Por este termo você garante à nossa Empresa o direito e licença
        royalty-free, perpétuo, irrevocável, global não exclusivo de usar,
        reproduzir, modificar, adaptar, publicar, traduzir, criar trabalhos
        derivados, distribuir, representar e expor todo o conteúdo, observações,
        sugestões, ideias, desenhos ou outras informações comunicadas à nossa
        Empresa por esse site (juntos, denominados agora como “Envios”), e
        incorporar qualquer Envio em outros trabalhos em qualquer formato, mídia
        ou tecnologia conhecida hoje, ou ainda a ser desenvolvida. Nossa Empresa
        não será obrigada a tratar nenhum Envio como confidencial e pode usar
        qualquer Envio em seu negócio (incluindo e não se limitando a produtos
        ou propaganda) sem ser imputada nenhuma responsabilidade por royalties
        ou qualquer outra consideração de qualquer tipo e como resultado não
        fica responsável por qualquer similaridade que possa aparecer em
        operações futuras da Empresa.
      </S.GeneralText>
      <S.GeneralText>
        Nossa Empresa tratará qualquer informação pessoal que você submeter por
        esse site de acordo com as Políticas de Privacidade do site.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Avisos</S.GeneralSubTitle>
      <S.GeneralText>
        Você entende que nossa Empresa não pode e não garante que arquivos
        disponíveis para download da Internet estejam livres de vírus, worms,
        cavalos de Tróia ou outro código que possa manifestar propriedades
        contaminadoras, ou destrutivas. Você é responsável por implementar
        procedimentos e checkpoints suficientes para satisfazer seus requisitos
        de segurança e por manter meios externos a este site para reconstrução
        de qualquer dado perdido. Nossa Empresa não assume nenhuma
        responsabilidade ou risco pelo uso da internet.
      </S.GeneralText>
      <S.GeneralText>
        A{" "}
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          {" "}
          Aprendendo Desenhar
        </S.GeneralLink>{" "}
        trabalha para manter todos os conteúdos sempre atualizados, porém, as
        plataformas mostradas nas aulas podem sofrer alterações que fogem ao
        controle da Empresa.
      </S.GeneralText>
      <S.GeneralText>
        Caso encontre alguma página que esteja desatualizada, poderá entrar em
        contato pela nossa área de
        <S.GeneralLink
          to="/contact"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          {" "}
          contato
        </S.GeneralLink>
        .
      </S.GeneralText>
      <S.GeneralText>
        O USO DESTE SITE É DE SUA TOTAL RESPONSABILIDADE. O CONTEÚDO É OFERECIDO
        COMO ESTÁ E SEM GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS.
        NOSSA EMPRESA NÃO GARANTE QUE AS FUNÇÕES OU CONTEÚDO PRESENTE NESTE SITE
        SERÁ CONTÍNUO, LIVRE DE ERROS, QUE FALHAS SERÃO CORRIGIDAS OU QUE ESTE
        SITE E SERVIDOR QUE O TORNA DISPONÍVEL ESTÁ LIVRE DE VÍRUS, OU OUTROS
        COMPONENTES DESTRUTIVOS. NOSSA EMPRESA NÃO GARANTE OU FAZ QUALQUER
        REPRESENTAÇÃO RELACIONADA AO USO, OU RESULTADOS DO USO DO CONTEÚDO EM
        TERMOS DE PRECISÃO, CONFIABILIDADE OU OUTROS. O CONTEÚDO PODE INCLUIR
        IMPRECISÕES TÉCNICAS OU ERROS TIPOGRÁFICOS E A EMPRESA PODE FAZER
        MUDANÇAS E MELHORIAS A QUALQUER MOMENTO. VOCÊ, E NÃO A NOSSA EMPRESA,
        ASSUME O CUSTO DE QUALQUER SERVIÇO, REPARO OU CORREÇÃO NECESSÁRIOS NO
        CASO DE QUALQUER PERDA, OU DANO CONSEQUENTE DO USO DESTE SITE, OU SEU
        CONTEÚDO. NOSSA EMPRESA NÃO OFERECE GARANTIA QUE O USO DESTE CONTEÚDO
        NÃO INFRINGIRÁ O DIREITO DE OUTRO E NÃO ASSUME QUALQUER RESPONSABILIDADE
        POR ERROS OU OMISSÕES EM TAL CONTEÚDO.
      </S.GeneralText>
      <S.GeneralText>
        Toda informação neste site seja de natureza histórica ou prospectivas é
        válida somente para a data que foi publicada no site e a Empresa não se
        compromete com nenhuma obrigação de atualizar tal informação depois que
        é publicada ou remover tal informação deste site caso ela não seja mais
        precisa ou completa.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">
        Limitação de responsabilidade da Empresa
      </S.GeneralSubTitle>
      <S.GeneralText>
        A EMPRESA, SUAS FILIAIS, AFILIADOS, LICENCIANTES, PROVEDORES DE SERVIÇO,
        PROVEDORES DE CONTEÚDO, EMPREGADOS, AGENTES, ADMINISTRADORES E DIRETORES
        NÃO SERÃO RESPONSÁVEIS POR QUALQUER DANO EVENTUAL, DIRETO, INDIRETO,
        PUNITIVO, REAL, CONSEQUENTE, ESPECIAL, EXEMPLAR OU DE QUALQUER OUTRO
        TIPO, INCLUINDO PERDA DE RECEITA OU RENDA, DOR E SOFRIMENTO, ESTRESSE
        EMOCIONAL OU SIMILARES MESMO QUE A EMPRESA TENHA ACONSELHADO SOBRE A
        POSSIBILIDADE DE TAIS DANOS. DE NENHUMA FORMA A RESPONSABILIDADE
        COLETIVA DA EMPRESA E SUAS FILIAIS, AFILIADOS, LICENCIANTES, PROVEDORES
        DE SERVIÇO, PROVEDORES DE CONTEÚDO, EMPREGADOS, AGENTES, ADMINISTRADORES
        E DIRETORES EM RELAÇÃO A TERCEIROS (INDEPENDENTE DO TIPO DE AÇÃO, SEJA
        POR CONTRATO OU QUALQUER OUTRO) EXCEDERÁ A QUANTIA DE R$100 OU O VALOR
        PAGO À EMPRESA POR TAL CONTEÚDO, PRODUTO OU SERVIÇO DO QUAL A QUESTÃO
        TENHA SIDO LEVANTADA.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Indenização</S.GeneralSubTitle>
      <S.GeneralText>
        Você vai indenizar e isentar a Empresa, suas filiais, afiliados,
        licenciantes, provedores de serviço, provedores de conteúdo, empregados,
        agentes, administradores e diretores (referidas agora como Partes
        Isentas) de qualquer violação desse Termo de Uso por você, incluindo o
        uso do Conteúdo diferente do expresso aqui. Você concorda que as Partes
        Isentas não têm responsabilidade ou conexão com qualquer violação, ou
        uso não autorizado e você concorda em remediar toda e qualquer perda,
        dano, julgamento, prêmios, custo, despesas e honorários advocatícios das
        Partes Isentas ligadas a violação. Você também indenizará e isentará as
        Partes Isentas de qualquer reivindicação de terceiros resultante do uso
        da informação contida neste site.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Marcas Registradas</S.GeneralSubTitle>
      <S.GeneralText>
        Marcas e logos presentes neste site são propriedade da Empresa ou da
        parte que as disponibilizaram para a Empresa. A Empresa e as partes que
        disponibilizaram marca e logo detém todos os direitos sobre as mesmas.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Política de Reembolso</S.GeneralSubTitle>
      <S.GeneralText>
        Toda indicação feita pelo
        <S.GeneralLink
          to="/"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          {" "}
          Aprendendo Desenhar
        </S.GeneralLink>{" "}
        possui uma política de reembolso de no mínimo 7 dias, porém, a mesma
        pode variar de produto a produto, chagando a no máximo 30 dias. Todo
        reembolso deve ser tratado diretamente pelo produtor do conteúdo ou com
        a plataforma onde foi feita a contratação. Nós nos reservamos no direito
        de ser isento de quaisquer problemas que possam vir oriundos de
        reembolso. Nos colocamos a disposição para tirar dúvidas e ajudar no
        processo caso necessário.
      </S.GeneralText>
      <S.GeneralSubTitle align="left">Segurança</S.GeneralSubTitle>
      <S.GeneralText>
        Toda senha usada para este site é somente para uso individual. Você é
        responsável pela segurança de sua senha (se for o caso). A Empresa tem o
        direito de monitorar a segurança de sua senha e ao seu critério pode
        pedir que você a mude. Se você usar qualquer senha que a Empresa
        considere insegura, a Empresa tem o direito de requisitar que a senha
        seja modificada e/ou cancelar a sua conta.
      </S.GeneralText>
      <S.GeneralText>
        É proibido usar qualquer serviço ou ferramenta conectada a este site
        para comprometer a segurança, ou mexer com os recursos do sistema, ou
        contas. O uso ou distribuição de ferramentas destinadas para comprometer
        a segurança (ex: programas para descobrir senha, ferramentas de crack ou
        de sondagem da rede) são estritamente proibidos. Se você estiver
        envolvido em qualquer violação da segurança do sistema, a Empresa se
        reserva o direito de fornecer suas informações para os administradores
        de sistema de outros sites para ajudá-los a resolver incidentes de
        segurança. A Empresa se reserva o direito de investigar potenciais
        violações a esse Termo de Uso.
      </S.GeneralText>
      <S.GeneralText>
        A Empresa se reserva o direito de cooperar totalmente com as autoridades
        competentes ou pedidos da justiça para que a Empresa revele a identidade
        de qualquer pessoa que publique e-mail, mensagem ou disponibilize
        qualquer material que possa violar esse Termo de Uso.
      </S.GeneralText>
      <S.GeneralText>
        AO ACEITAR ESSE ACORDO VOCÊ ISENTA A EMPRESA DE QUALQUER CONSEQUÊNCIA
        RESULTANTE DE QUALQUER AÇÃO DA EMPRESA DURANTE OU COMO RESULTADO DE SUAS
        INVESTIGAÇÕES, OU DE AÇÕES TOMADAS RESULTANTES DE INVESTIGAÇÕES TANTO DA
        EMPRESA QUANTO DAS AUTORIDADES DE JUSTIÇA COMPETENTES.
      </S.GeneralText>
      <S.GeneralText>
        A Empresa se coloca à disposição para eventuais dúvidas e
        esclarecimentos sobre este Termos de Uso.
      </S.GeneralText>
    </S.GeneralWrapper>
  </Layout>
)

export default TermsPage
