import styled from "styled-components"
import media from "styled-media-query"

import AniLink from "gatsby-plugin-transition-link/AniLink"

export const GeneralWrapper = styled.main`
  padding: 2rem 1.5rem;
  color: var(--white);
  background-color: var(--black_background_contrast);

  ${media.lessThan("large")`
    padding:.5rem;
  `}
`

export const GeneralTitle = styled.h1`
  font-size: 3rem;
  text-align: ${props => (props.align ? props.align : "center")};
  margin: 0 auto 4rem;
`

export const GeneralSubTitle = styled.h2`
  margin: 2.25rem auto;
  text-align: ${props => (props.align ? props.align : "center")};
  font-size: 2.25rem;
  text-transform: uppercase;
`
export const GeneralHighlight = styled.p`
  margin: 1.5rem auto;
  line-height: 1.5;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
  padding: 2.5rem;
  text-transform: uppercase;

  ${media.lessThan("large")`
    padding: .5rem;
  `}
`

export const GeneralText = styled.p`
  line-height: 1.5;
  font-size: 1.25rem;
  margin: 1.5rem auto;
`

export const GeneralCallToAction = styled.a`
  display: block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--dark_orange);

  &:hover {
    text-decoration: underline;
  }
`

export const GeneralLink = styled(AniLink)`
  text-decoration: none;
  cursor: pointer;
  color: var(--dark_orange);

  &:hover {
    text-decoration: underline;
  }
`
export const GeneralButtonInner = styled(AniLink)`
  padding: 1.5rem;
  border-radius: 10rem;
  color: var(--white);
  text-decoration: none;
  border: none;
  background-color: var(--dark_orange);
  margin: 2rem 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--orange);
  }
`
